import { Link } from 'react-router-dom';

const Title = ({ children }: any) => <h2 className="w-full text-2xl font-bold">{children}</h2>;

const Text = ({ children }: any) => <p className="w-full text-lg">{children}</p>;

const List = ({ children }: any) => (
  <ul className="flex list-inside list-disc flex-col items-start gap-2 pl-4 text-lg">{children}</ul>
);

const TableHead = ({ children }: any) => (
  <thead className="border-b border-gray-200 px-4 py-2">{children}</thead>
);

const TableRow = ({ children }: any) => <tr className="border-b border-gray-200">{children}</tr>;

const TableCell = ({ children }: any) => <td className="p-4">{children}</td>;

export const PrivacyAustralia = () => {
  return (
    <div className="mx-auto box-border flex w-full max-w-5xl flex-col items-start gap-6 p-6 pb-24">
      <h1 className="w-full text-center text-5xl font-medium">Privacy Policy</h1>

      {/* Header */}
      <Text>
        Home ANZ Pty Ltd (ACN 684 368 983) of CM Partners Pty Limited, Level 1, 56 Clarence Street,
        Sydney NSW 2000
      </Text>
      <Text>Updated: 20 March 2025</Text>

      {/* 1 INTRODUCTION */}
      <Title>1 INTRODUCTION</Title>
      <Title>1.1 Important information and who we are</Title>
      <Text>
        Welcome to Home ANZ Pty Ltd's Privacy Policy (“<strong>Privacy Policy</strong>”).
      </Text>
      <Text>
        At Home ANZ Pty Ltd (“<strong>Home</strong>”, “<strong>we</strong>”, “<strong>us</strong>”,
        or “<strong>our</strong>”) we are committed to protecting and respecting your privacy and
        Personal Information (or data) in compliance with the Privacy Act 1988 (Cth) and its
        amendments (<strong>Privacy Act</strong>) and all other relevant laws.
      </Text>
      <Text>
        This Privacy Policy explains how we collect, store, use, process and keep your information
        (or data) safe. The Privacy Policy will tell you about your privacy rights, how the law
        protects you, and inform our employees and staff members of all their obligations and
        protocols when processing data.
      </Text>
      <Text>The individuals from which we may gather and use data can include:</Text>
      <List>
        <li>Customers</li>
        <li>Suppliers</li>
        <li>Business contacts</li>
        <li>Employees/Staff Members</li>
        <li>Third parties connected to our customers</li>
        <li>
          and any other people that Home has a relationship with or may need to contact to provide
          services to you.
        </li>
      </List>
      <Text>
        This Privacy Policy applies to all our employees and staff members and all Personal
        Information processed at any time by us.
      </Text>

      <Title>1.2 Who is Your Data Controller</Title>
      <Text>
        Home (in conjunction with its parent company Cumbria Capital Ltd (UK company)) is your data
        controller and responsible for your Personal Information.
      </Text>

      <Title>1.3 Complaints and Further Questions</Title>
      <Text>
        If you have any questions, feedback or concerns about this Privacy Policy or about our
        dealings with your Personal Data, including our compliance with the Privacy Act, you may
        contact us at any time using our contact details found at the end of this Privacy Policy.
      </Text>
      <Text>
        We will endeavour to acknowledge your request as soon as possible and provide you with a
        decision on your complaint as soon as reasonably practicable.
      </Text>
      <Text>
        We will investigate your concerns and manage your complaint directly with you. If you are
        not satisfied with the outcome received, you may refer your complaint to the Office of the
        Australian Information Commissioner (<strong>OAIC</strong>) who can be contacted at{' '}
        <a
          className="underline"
          href="https://www.oaic.gov.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.oaic.gov.au
        </a>{' '}
        or by telephone on 1300 363 992.
      </Text>

      {/* 2 DATA COLLECTION */}
      <Title>2 DATA COLLECTION</Title>
      <Title>2.1 Types of Data / Privacy Policy Scope</Title>
      <Text>
        “<strong>Personal Information</strong>” or “<strong>Personal Data</strong>” means any
        information about an individual from which that person can be identified. It does not
        include data where the identity has been removed (anonymous data).
      </Text>
      <Text>
        We may collect, use, store and transfer different kinds of Personal Data about you which we
        have grouped together below. Not all of the following types of data will necessarily be
        collected from you, but this is the full scope of data that we collect and when we collect
        it from you:
      </Text>
      <List>
        <li>
          Profile/Identity Data: This is data relating to your first name, last name, gender, date
          of birth.
        </li>
        <li>
          Property data: This is data relating to your move, property address, information available
          in your property information pack and move status.
        </li>
        <li>
          Contact Data: This is data relating to your phone number, residential and postal addresses
          and email addresses.
        </li>
        <li>
          Marketing and Communications Data: This is your preference in receiving marketing
          information and other information from us.
        </li>
        <li>
          Billing Data: This is information relating to your debit and credit card information such
          as the name attached to your payment details and your billing address.
        </li>
        <li>Financial Data: These are your banking details e.g. your account number and BSB.</li>
        <li>
          Transactional Data: This is information regarding details and records of all payments you
          have made for our services or products.
        </li>
      </List>
      <Text>
        We also collect, use and share aggregated data such as rental market trends, home moving
        data and product trends (<strong>Aggregated Data</strong>). Aggregated Data could be derived
        from your Personal Data but is not considered Personal Data in law as this data will not
        directly or indirectly reveal your identity. However, if we combine or connect Aggregated
        Data with your Personal Data so that it can directly or indirectly identify you, we treat
        the combined data as Personal Data which will be used in accordance with this Privacy
        Policy.
      </Text>
      <Text>
        We may also aggregate data to enable research or analysis so that we can better understand
        and serve you and others. For example, we may conduct research on your demographics and
        usage. Although this aggregated data may be based in part on Personal Data, it does not
        identify you personally. We may share this type of anonymous data with others, including
        service providers, our affiliates, agents and current and prospective business partners.
      </Text>
      <Text>
        We do not collect any sensitive information about you (this includes details about your race
        or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political
        opinions, trade union membership, information about your health, and genetic and biometric
        data). Nor do we collect any information about criminal convictions and offences.
      </Text>

      <Title>2.2 How we collect Personal Data</Title>
      <Text>
        In so far as possible, we collect Personal Data directly from you, verbally or in writing
        (including via telephone, mail or electronic communication).
      </Text>
      <Text>
        There may be instances where Personal Data about you will be collected indirectly from a
        third party. These third parties may include financial institutions, your representatives
        and government agencies.
      </Text>
      <Text>
        From time to time, we may receive information that we have not asked for about you from
        third parties. We will only keep, use and disclose this information as permitted by law.
      </Text>
      <Text>
        We may also collect your Personal Data by using electronic verification services or
        searching publicly available sources of information such as public registers. Any
        information collected from publicly available sources will be protected in the same way as
        the information you voluntarily disclose.
      </Text>
      <Text>
        If you provide Personal Data to us about someone else, you must ensure that you are entitled
        to disclose that information to us, that the other person has consented to you providing us
        with the information, and that we can collect, use and disclose that information as set out
        in this Privacy Policy without having to take any further steps required under law (such as
        obtaining consent directly from that person). This means that if you provide us with
        Personal Data about someone else, you must make sure that the individual concerned
        understands the matters set out in this Privacy Policy and has provided their consent to be
        bound by this Privacy Policy.
      </Text>

      <Title>2.3 Why we collect Personal Data</Title>
      <Text>
        We only collect, hold and use Personal Data which is reasonably necessary for us to conduct
        our business activities and provide our products and services to you or to comply with the
        law. The main purposes for which we will generally collect and use Personal Data include:
      </Text>
      <List>
        <li>Establishing your identity;</li>
        <li>
          Setting up, administering and managing our products and services and systems, including
          the management and administration of an account with us;
        </li>
        <li>Providing our products and services to you;</li>
        <li>Performing administrative functions;</li>
        <li>Telling you about our other products and services;</li>
        <li>
          Direct marketing and managing our relationship with you, including providing you with
          information on our latest events, special offers and news;
        </li>
        <li>
          Notifying you about any changes to our Site, including improvements and service changes;
        </li>
        <li>To comply with any associated contractual, legal or regulatory obligations.</li>
      </List>

      <Title>2.4 Use of Cookies</Title>
      <Text>
        Our Site uses “cookies” and similar online behavioural tracking techniques to help analyse
        how people use our Site. A cookie is a small text file placed on your computer by a web
        server when you access a website. Information generated by the cookie about uses of the
        website is then used to compile statistical reports on website activity by us, helping
        improve the overall experience for users.
      </Text>
      <Text>
        Cookies are designed to identify devices rather than individual users. We may, however, use
        them to help personalise your visit to our Site by linking the cookie on your device to your
        personal data stored on our system.
      </Text>
      <Text>
        If personal information about you is collected by third parties on any website you have
        accessed through our Site, we may also collect or have access to that information as part of
        our arrangement with those third parties.
      </Text>
      <Text>
        You may choose if and how to accept cookies by configuring the preferences and options in
        your web browser. However, if you choose to disable cookies, you may not be able to access
        some parts of our Site.
      </Text>
      <Text>
        Our Site may contain links to other websites. Please be aware that we are not responsible
        for the privacy practices of such other sites. When you go to other sites from our Site, we
        advise you to be aware and read their privacy policy.
      </Text>

      {/* 3 HOW WE USE YOUR PERSONAL DATA */}
      <Title>3 HOW WE USE YOUR PERSONAL DATA</Title>
      <Title>3.1 Our Uses</Title>
      <Text>
        We will only use your Personal Data when the law allows us to. Set out below is a table
        containing the different types of Personal Data we collect and use. Examples provided in the
        table below are indicative in nature, and the purposes for which we use your data may be
        broader than described. For further inquiries please contact us using our contact details
        found at the end of this Privacy Policy.
      </Text>
      <table className="table-auto">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Activity</b>
            </TableCell>
            <TableCell>
              <b>Type of data</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <tbody>
          <TableRow>
            <TableCell>When a customer purchases a product</TableCell>
            <TableCell>
              Profile/Identity Data
              <br />
              Contact Data
              <br />
              Billing Data
              <br />
              Financial Data
              <br />
              Transactional Data
              <br />
              Customer Support Data
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>When a customer signs up to our services and creates a profile</TableCell>
            <TableCell>
              Profile/Identity Data
              <br />
              Contact Data
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>when a customer opts in to marketing communications</TableCell>
            <TableCell>Marketing and Communications Data</TableCell>
          </TableRow>
        </tbody>
      </table>

      <Title>3.2 Marketing and Content Updates</Title>
      <Text>
        You will receive marketing and new content communications from us if you have created an
        account and chosen to opt into receiving those communications. From time to time we may make
        suggestions and recommendations to you about goods or services that may be of interest to
        you, changes to our organisation or new products or services being offered by us or any
        company with whom we are associated.
      </Text>

      <Title>3.3 Change of Purpose</Title>
      <Text>
        We will only use your Personal Data for the purposes for which we collected it, unless we
        reasonably consider that we need to use it for another reason and that reason is compatible
        with the original purpose. If you wish to get an explanation as to how the processing for
        the new purpose is compatible with the original purpose, please contact us.
      </Text>
      <Text>
        If we need to use your Personal Data for an unrelated purpose, we will seek your explicit
        consent to do so.
      </Text>
      <Text>
        Please note that we may process your Personal Data without your knowledge or consent, in
        compliance with the above rules, where this is required or permitted by law.
      </Text>

      <Title>3.4 Disclosure of Personal Data</Title>
      <Text>
        To enable us to maintain a successful relationship with you, we may disclose your Personal
        Data to other organisations that provide products or services used or marketed by us.
      </Text>
      <Text>We may disclose your Personal Data:</Text>
      <List>
        <li>
          to other organisations that are involved in managing or administering our products and
          services to you;
        </li>
        <li>
          to third parties who assist with the purposes of why we collect your Personal Information,
          on the basis that the information will be shared with that third party on a confidential
          basis and subject to the standards of data protection set out by law;
        </li>
        <li>
          to third parties necessary to assist us in investigating and preventing any potential,
          suspected or actual breaches of law or fraudulent activities;
        </li>
        <li>
          to regulatory bodies and government agencies or other third parties, where required under
          or authorised by law;
        </li>
        <li>
          to our legal advisers, debt collection agencies, credit reference bodies or industry
          associations (in circumstances where you are in default of a contractual commitment to
          us);
        </li>
        <li>to anyone or any organisation, where you have provided us consent; and</li>
        <li>where we are required to do so by law.</li>
      </List>
      <Text>
        We will disclose your Personal Data only for the purpose/s for which we collected the
        Personal Data.
      </Text>
      <Text>
        Prior to disclosing any of your Personal Data to another person or organisation, we will
        take reasonable steps to satisfy ourselves that;
        <List>
          <li>
            the person or organisation to which we disclose Personal Data has a commitment to
            protecting your Personal Data at least equal to our commitment; or, if required, you
            have consented to us doing so.
          </li>
        </List>
      </Text>

      {/* 4 METHODS OF COMMUNICATION */}
      <Title>4 METHODS OF COMMUNICATION</Title>
      <Text>
        We may contact you using various communication methods, including but not limited to email,
        SMS, telephone calls, and direct messaging applications (e.g., WhatsApp, Facebook
        Messenger). We ensure all communications comply with the latest Privacy Act requirements.
      </Text>
      <Title>4.1 Recording of Telephone Conversations</Title>
      <Text>We may record telephone conversations for the following purposes:</Text>
      <List>
        <li>
          <b>Training and Quality Assurance:</b> To improve our services and ensure high-quality
          customer support.
        </li>
        <li>
          <b>Compliance and Dispute Resolution:</b> To maintain accurate records of conversations
          where required.
        </li>
      </List>
      <Text>
        You will be notified at the start of any call if it is being recorded. These recordings will
        be securely stored and retained only for as long as necessary to fulfill the purposes stated
        above, in accordance with our data retention policy.
      </Text>
      <Title>4.2 Marketing Communications</Title>
      <Text>
        We will only send marketing communications by email, SMS, or direct messaging applications
        if you have given explicit consent, in line with the Spam Act 2003 (Cth) and the Privacy
        Act. You may withdraw your consent at any time by following the unsubscribe instructions
        included in each communication or by contacting us directly using the contact details
        provided at the end of this Privacy Policy. If you wish to opt-out of receiving direct
        marketing communications from third party organisations to whom we have disclosed your
        Personal Data, you may make a separate request to that organisation.
      </Text>
      <Title>4.3 Your Rights</Title>
      <Text>You have the right to:</Text>
      <List>
        <li>
          Object to or restrict the processing of your personal data for communication purposes.
        </li>
        <li>Opt-out of specific communication methods at any time.</li>
        <li>Request access or correction of your communication preferences or Personal Data.</li>
      </List>
      <Text>
        To exercise any of these rights or for more information, please contact us using the contact
        details provided at the end of this Privacy Policy.
      </Text>
      <Text>
        If you would like to request access to, or correction of, your Personal Data, you can
        contact us using the contact details provided at the end of this Privacy Policy. If any of
        the Personal Data we hold about you is incorrect, inaccurate or out of date you may request
        that we correct the information. If appropriate, we will correct the Personal Data. Your
        request will be dealt with within a reasonable time and if we refuse to provide you with
        access to, or to correct, the information, we will notify you of reasons for the refusal to
        the extent required and how you may complain about the refusal.
      </Text>
      <Title>4.4 Security Measures</Title>
      <Text>
        We take appropriate technical and organisational measures to ensure the security of all
        communication channels used and to protect your personal data, including recorded
        conversations, from unauthorised access, loss, or misuse.
      </Text>

      {/* 5 YOUR RIGHTS AND HOW YOU ARE PROTECTED BY US */}
      <Title>5 YOUR RIGHTS AND HOW YOU ARE PROTECTED BY US</Title>
      <Title>5.1 What Control Do I Have Over Home's Use of My Personal Data?</Title>
      <Text>
        You may delete your account at any time – this will remove your account page from our
        systems and our related software, but your Personal Data may remain securely stored by us in
        accordance with this Privacy Policy.
      </Text>
      <Text>
        Your account information will be protected by a password for your privacy and security. You
        need to prevent unauthorised access to your account and personal information by selecting
        and protecting your password appropriately and limiting access to your computer or device
        and by signing off after you have finished accessing your account.
      </Text>
      <Text>
        You can access information associated with your account by logging into the account you
        created with us.
      </Text>
      <Title>5.2 How Does Home Protect Customers' Personal Data?</Title>
      <Text>
        We are concerned with keeping your data secure and protecting it from inappropriate
        disclosure. We implement a variety of security measures and practices in line with industry
        standards, including guidance from NIST, to ensure the security of your Personal Data on our
        systems. We enforce role-based access control (RBAC) and the principle of least privilege to
        ensure that users only have access to the data necessary for their role. All data in transit
        is encrypted using transport layer security (TLS), and backups are stored securely in
        multiple locations with immutable backups to prevent tampering. We enable encryption, access
        logging and strict cloud security policies, as well as data masking in non-production
        environments and anonymization to minimize the use of personally identifiable information.
        We mitigate security risks through input validation, regular code audits, and dependency
        management to keep third-party libraries up to date. Our systems generate audit logs to
        track data access and activities, while automated alerts notify administrators of any
        suspicious behavior. We also have a defined incident response plan to handle potential
        security breaches efficiently, and we adhere to GDPR and enforce data retention policies to
        define how long data is stored and when it should be securely deleted. Furthermore, we
        conduct ongoing employee training on cybersecurity best practices and phishing threats,
        along with regular penetration testing and disaster recovery drills to ensure system
        resilience.
      </Text>
      <Text>
        However, unfortunately no transmission of data over the internet is guaranteed to be
        completely secure. It may be possible for third parties not under the control of Home to
        intercept or access transmissions or private communications unlawfully. While we strive to
        protect your Personal Data, we cannot ensure or warrant the security of any Personal Data
        you transmit to us. Any such transmission is done at your own risk. If you believe that your
        interaction with us is no longer secure, please contact us.
      </Text>
      <Title>5.3 Opting Out Of Marketing Promotions</Title>
      <Text>
        You can ask us to stop sending you marketing messages at any time by opting out on our Site.
      </Text>
      <Text>
        When you opt out of receiving these marketing messages, we will continue to retain other
        Personal Data provided to us as a result of interactions with us not related to your
        marketing preferences.
      </Text>
      <Title>5.4 How to Request your Data and the Process for Obtaining it</Title>
      <Text>
        You will not have to pay a fee to access your Personal Data (or to exercise any of the other
        rights). However, if your request is clearly unfounded, we could refuse to comply with your
        request.
      </Text>
      <Text>
        We may need to request specific information from you to help us confirm your identity and
        ensure you have the right to access your Personal Data (or to exercise any of your other
        rights). This is a security measure to ensure that Personal Data is not disclosed to any
        person who has no right to receive it. We may also contact you to ask you for further
        information in relation to your request to speed up our response.
      </Text>

      {/* 6 YOUR DATA AND THIRD PARTIES */}
      <Title>6 YOUR DATA AND THIRD PARTIES</Title>
      <Title>6.1 Will We Share Your Data With Third Parties?</Title>
      <Text>
        We may share non-Personal Data with third parties, including third parties overseas. We may
        share your Personal Data with subcontractors or affiliates (subject to confidentiality
        obligations to use it only for the purposes for which we disclose it to them and pursuant to
        our instructions).
      </Text>
      <Text>Our current partners include:</Text>
      <List>
        <li>Residential Connections Pty Ltd (Compare &amp; Connect)</li>
        <li>Oxygen Broking Services Pty Ltd</li>
        <li>Muval Pty Ltd</li>
      </List>
      <Text>
        We may also share Personal Data with interested parties in the event that Home anticipates a
        change in control or the acquisition of all or part of our business or assets or with
        interested parties in connection with the licensing of our technology.
      </Text>
      <Text>
        If Home is sold or makes a sale or transfer, we may, in our sole discretion, transfer, sell
        or assign your Personal Data to a third party as part of or in connection with that
        transaction. Upon such transfer, the Privacy Policy of the acquiring entity may govern the
        further use of your Personal Data. In all other situations your Personal Data will still
        remain protected in accordance with this Privacy Policy (as amended from time to time).
      </Text>
      <Text>
        We may share your Personal Data at any time if required for legal reasons or in order to
        enforce our terms of service or this Privacy Policy.
      </Text>
      <Title>6.2 Third-Party Links</Title>
      <Text>
        This Site may include links to third-party sites, plug-ins and applications. Clicking on
        those links or enabling those connections may allow third parties to collect or share data
        about you. We do not control these third-party sites and are not responsible for their
        privacy statements. When you leave our Site, we encourage you to read the privacy policy of
        every site you visit.
      </Text>

      {/* 7 HOW LONG WILL WE RETAIN YOUR DATA FOR? */}
      <Title>7 HOW LONG WILL WE RETAIN YOUR DATA FOR?</Title>
      <Text>
        We will only retain your Personal Data for as long as reasonably necessary to fulfil the
        purposes we collected it for. We may retain your Personal Data for a longer period than
        usual in the event of a complaint or if we reasonably believe there is a prospect of
        litigation in respect to our relationship with you.
      </Text>

      {/* 8 AGE LIMIT FOR OUR USERS */}
      <Title>8 AGE LIMIT FOR OUR USERS</Title>
      <Text>
        You must not use Home unless you are aged 18 or older. If you are under 18 and you access
        Home by not disclosing your true age, you must immediately stop using Home.
      </Text>
      <Text>
        This website is not intended for non-adults and we do not knowingly collect data relating to
        non-adults.
      </Text>

      {/* 9 INTERNATIONAL TRANSFER OF DATA */}
      <Title>9 INTERNATIONAL TRANSFER OF DATA</Title>
      <Text>
        There may be instances where your Personal Data is disclosed, stored and processed to our
        associated entities or providers located in other overseas jurisdictions (including our
        parent company in the United Kingdom, Cumbria Capital Ltd).
      </Text>

      {/* 10 NOTIFICATION OF CHANGES AND ACCEPTANCE OF POLICY */}
      <Title>10 NOTIFICATION OF CHANGES AND ACCEPTANCE OF POLICY</Title>
      <Text>
        We keep our Privacy Policy under review and reserve the right to change it from time to time
        at our sole discretion. If we do so, we will update this Privacy Policy and it will apply to
        the Personal Data held by us from the time of publication. By using Home, you consent to the
        collection and use of Personal Data by us as set out in this Privacy Policy. Continued
        access or use of Home will constitute your express acceptance of any modifications to this
        Privacy Policy.
      </Text>

      {/* 11 NOTIFIABLE DATA BREACHES */}
      <Title>11 NOTIFIABLE DATA BREACHES</Title>
      <Text>
        Despite every effort to protect your Personal Data, there is always a possibility that a
        breach of our security may occur. We are required to notify you and the OAIC where there is
        a data breach that will likely result in serious harm to you. If we believe there has been a
        data breach that impacts your Personal Data and creates a likely risk of serious harm, we
        will notify you and the OAIC as soon as practicable and keep in close contact with you about
        the nature of the breach, the steps we are taking and what you can do to reduce the impacts
        to your privacy.
      </Text>
      <Text>
        If at any time you believe your Personal Data which we hold has been the subject of a data
        breach, please contact us immediately using the contact details provided at the end of this
        Privacy Policy.
      </Text>

      {/* 12 ANONYMITY */}
      <Title>12 ANONYMITY</Title>
      <Text>
        Where practicable, you have the option of dealing with us anonymously. However, if you deal
        with us in this manner, we may not be able to provide all of our products and services to
        you or interact with you in other ways set out in this Privacy Policy.
      </Text>

      {/* 13 INTERPRETATION */}
      <Title>13 INTERPRETATION</Title>
      <Text>
        All uses of the word "including" mean "including but not limited to" and the enumerated
        examples are not intended in any way to limit the term which they serve to illustrate. Any
        email addresses set out in this policy may be used solely for the purpose for which they are
        stated to be provided, and any unrelated correspondence will be ignored. Unless otherwise
        required by law, we reserve the right to not respond to emails, even if they relate to a
        legitimate subject matter for which we have provided an email address. As a matter of common
        sense, you are more likely to get a reply if your request or question is polite, reasonable
        and there is no relatively obvious other way to deal with or answer your concern or question
        (e.g. FAQs, other areas of our Site).
      </Text>
      <Text>
        Our staff are not authorised to contract on behalf of Home, waive rights or make
        representations (whether contractual or otherwise). If anything contained in an email from a
        Home address contradicts anything in this Privacy Policy, our terms or any official public
        announcement on our website, or is inconsistent with or amounts to a waiver of any Home
        rights, the email content will be read down to grant precedence to the latter. The only
        exception to this is genuine correspondence expressed to be from the Home legal department.
      </Text>

      {/* OUR CONTACT DETAILS */}
      <Title>OUR CONTACT DETAILS</Title>
      <Text>
        Email:{' '}
        <Link to="mailto:help@home.cc" className="underline hover:no-underline">
          help@home.cc
        </Link>
      </Text>
      <Text>Level 1, 56 Clarence Street, Sydney NSW 2000</Text>
      <Text>Tel: +61 2 6618 3388</Text>
    </div>
  );
};

export default PrivacyAustralia;
