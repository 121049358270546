import React from 'react';

const Title = ({ children }: any) => <h2 className="w-full text-2xl font-bold">{children}</h2>;

const Text = ({ children }: any) => <p className="w-full text-lg">{children}</p>;

export const TermsAustralia = () => {
  return (
    <div className="mx-auto box-border flex w-full max-w-5xl flex-col items-start gap-6 p-6 pb-24">
      <h1 className="w-full text-center text-5xl font-medium">TERMS &amp; CONDITIONS</h1>
      <Text>
        Home ANZ Pty Ltd (ACN 684 368 983) of Level 1, 56 Clarence Street, Sydney NSW 2000.
      </Text>

      <Title variant="h2">1. Introduction</Title>
      <Text>
        In these Terms of Service (“TOS”), "we," "us," “our” or "Home" means and refers to Home ANZ
        Pty Ltd ACN 684 368 983 or its related bodies corporate. By accessing and using Home, you
        agree to be bound by these TOS. Please read these TOS carefully before using our service.
      </Text>
      <Text>Also, in these TOS, data means information and vice versa.</Text>

      <Title variant="h2">2. Data Processing Agreement</Title>
      <Text>
        2.1. Home acts as a data processor on behalf of property professionals and processes data in
        accordance with the instructions provided by the property professionals (including but not
        limited to, agents, conveyancers, brokers, lenders, rental agencies, reference agencies and
        build to rent operators.)
      </Text>
      <Text>
        2.2. The property professionals (data controllers) retain responsibility for ensuring the
        lawfulness of their data processing activities and obtaining necessary consents from data
        subjects.
      </Text>

      <Title variant="h2">3. Data Collection and Usage</Title>
      <Text>
        3.1. Home collects and processes data from property professionals and their customers. This
        data may include personal information, property details, and other relevant information
        required for the functioning of our service.
      </Text>
      <Text>
        3.2. The data collected will be used for facilitating property transactions, providing the
        agreed-upon services, and for sharing data with agreed third parties, as outlined in Section
        4.
      </Text>

      <Title variant="h2">4. Data Sharing with Third Parties</Title>
      <Text>
        4.1. Home may share data with agreed third parties for specific purposes, such as enhancing
        the services offered, facilitating collaborations. Such sharing will be done in compliance
        with applicable data protection laws and unless otherwise agreed, you agree to us providing
        such data to third parties for the purpose of providing our service to you.
      </Text>

      <Title variant="h2">5. Data Transmission</Title>
      <Text>
        5.1. Home implements reasonable technical and organisational measures to safeguard data from
        unauthorised access, disclosure, alteration, and destruction.
      </Text>
      <Text>
        5.2. However, it is important to note that no method of data transmission over the internet
        or electronic storage is entirely secure. We cannot guarantee or warrant the absolute
        security of any information transmitted to, or held by, us in this manner and we will not be
        liable for any loss, harm or damage suffered by you as a result of transmitting information
        to us, or storage thereof, in this manner.
      </Text>

      <Title variant="h2">6. Data Retention</Title>
      <Text>
        6.1. Home will retain the data for as long as necessary to fulfil the purposes for which it
        was collected, or as required by applicable laws and regulations.
      </Text>

      <Title variant="h2">7. Data Subject Rights</Title>
      <Text>
        7.1. Property professionals and their customers have the right to access, rectify, erase,
        and restrict the processing of their data. To exercise these rights, please refer to our
        Privacy Policy or contact us using the information provided at the end of these TOS.
      </Text>

      <Title variant="h2">8. Consent and Withdrawal</Title>
      <Text>
        8.1. By using Home, property professionals and their customers provide explicit consent to
        the processing and sharing of their data as described in these TOS and the Privacy Policy.
      </Text>
      <Text>
        8.2. Consent may be withdrawn at any time by following the procedures outlined in our
        Privacy Policy, including in circumstances where an account is cancelled or deleted.
      </Text>

      <Title variant="h2">9. Cookies and Tracking Technologies</Title>
      <Text>
        9.1. Home may use cookies and similar tracking technologies to enhance user experience and
        gather usage data. By using our service, you consent to our use of cookies as described in
        our Privacy Policy.
      </Text>

      <Title variant="h2">10. Privacy Policy</Title>
      <Text>
        10.1 Your privacy and maintaining the confidentiality of your personal information is
        important to us. Please read our separate Privacy Policy to understand more about how we
        protect your personal information. All personal information (including information about
        your access to this Site) will be collected and treated in accordance with our Privacy
        Policy.
      </Text>

      <Title variant="h2">11. Commission</Title>
      <Text>
        11.1 Our services are provided to you at no charge. In some cases, we may receive a
        commission from introducing you or registering you with a service provider. The commission
        goes to pay for our time and costs or provision of the services, costs charged by agencies,
        and any potential profits, without which we would not be able to provide our services. The
        commission amount will vary depending on the supplier, the product or service you sign up.
        By using our services, you consent to us receiving commission payments.
      </Text>

      <Title variant="h2">12. Responsibility for payments to suppliers</Title>
      <Text>
        12.1 You acknowledge and agree that any charges or fees set by a service provider for their
        services are your sole responsibility. You are obligated to make any payments due directly
        to service providers in accordance with their terms and conditions. We have no liability or
        responsibility to you for any charges set by service providers or any payments you make to
        those service providers. Furthermore, you agree to honour any financial obligations,
        including but not limited to direct debit arrangements, with the service providers. Any
        failure to make timely payments may result in the termination of services by the service
        provider, and Home shall not be liable for any such consequences.
      </Text>

      <Title variant="h2">13. Links to Third-Party Sites</Title>
      <Text>
        13.1 As a convenience to users, this Site may link to other sites owned and operated by
        third parties and not maintained by Home. However, even if such third parties are affiliated
        with Home, Home has no control over these linked sites, all of which have separate privacy
        and data collection practices and legal policies independent of Home. We take reasonable
        care to ensure the information we show via Home is correct but it is not always possible
        despite our best efforts.
      </Text>
      <Text>
        13.2 We make no representations and provide no warranties regarding the accuracy or
        suitability of, and accept no responsibility or liability for, anything you obtain via these
        third-party sites. A link from our Site or application does not imply Home’s endorsement,
        approval or agreement with the views or contents of those third-party sites.
      </Text>
      <Text>
        13.3 Software belonging to third parties may be accessed and downloaded through third party
        links from our Site and application. Such software is likely to be subject to licensing
        terms imposed by the owner of that software. The use of third-party software is at your own
        risk and to the maximum extent permitted by law, we exclude all liability in relation to
        using or downloading any software that may be accessed from our Site.
      </Text>
      <Text>
        Please take care to confirm information before you commit to take our services. Viewing such
        third party sites is entirely at your own risk.
      </Text>

      <Title variant="h2">14. Updates to the TOS</Title>
      <Text>
        14.1. Home reserves the right to modify these TOS at any time and without notice. Any
        amendments will come into effect immediately and automatically upon publication of those
        amendments. Your continued use of our service will constitute acceptance of the revised TOS.
        These TOS are effective until terminated by us, which we may do at any time without notice
        to you. In the event of termination, all restrictions imposed on you by these TOS and
        limitation of liability set out in these TOS will survive. You acknowledge that neither us,
        nor any other person, will have any liability to you for any reason whatsoever arising as a
        consequence of the termination of your access to our Site or services.
      </Text>

      <Title variant="h2">15. Liability and Disclaimers</Title>
      <Text>
        15.1. To the maximum extent permitted by law, Home shall not be liable for any direct,
        indirect, incidental, special, consequential, or punitive damages, whether arising out of
        contract, statute, tort (including negligence) or otherwise arising out of the use or
        inability to use our service, the use of the Site, its content or reliance on the
        information on it. This includes, but is not limited to, the transmission of any computer
        virus. Your use of, or reliance on, any information or materials on this Site is entirely at
        your own risk, for which we shall not be liable.
      </Text>

      <Title variant="h2">16. Copyright, Trademark and Restriction of Use</Title>
      <Text>
        16.1 Our Site contains material which is owned by or licensed to us. This material includes,
        but is not limited to, the content, design, layout, look, appearance, trademarks and
        graphics. You are not permitted to reproduce the documents, information, or materials on
        this Site for any purposes, including the purposes of sale or the use by any third party. In
        particular, you are not permitted to republish, upload, transmit electronically or otherwise
        or distribute any of the materials, documents or products that may be available for download
        from time to time from this Site. You may not, except with our express written permission,
        distribute or commercially exploit the content. Nor may you transmit it or store it in any
        other website or other form of electronic retrieval system.
      </Text>
      <Text>
        16.2 Home expressly reserves all intellectual property rights, including all copyright and
        trademark rights in all documents, information and materials on this Site and we reserve the
        right to take action against you if you breach any of these TOS.
      </Text>

      <Title variant="h2">17. Governing Law and Jurisdiction</Title>
      <Text>
        17.1. These TOS shall be governed by and construed in accordance with the laws of New South
        Wales, Australia. Any disputes arising under or in connection with these TOS shall be
        subject to the exclusive jurisdiction of the courts of New South Wales, Australia.
      </Text>

      <Title variant="h2">18. Warranties</Title>
      <Text>
        18.1 We will provide our services with due care and skill. Save as expressly indicated in
        these TOS, our services are provided on an "as-is" and "as available" basis, and, to the
        extent permitted by applicable law, we expressly exclude all express and implied warranties
        and conditions of any kind, including all warranties or conditions of satisfactory quality,
        fitness for a particular purpose, title, quiet enjoyment, accuracy, compatibility or
        non-infringement. We make no warranty that our services will meet your requirements, will be
        available on an uninterrupted, timely, secure, or error-free basis, or will be accurate,
        reliable, free of viruses or other harmful code, complete, legal, or safe.
      </Text>

      <Title variant="h2">19. Indemnification</Title>
      <Text>
        19.1 To the maximum extent permitted by law, you agree to indemnify and hold harmless Home
        (and our officers, employees, contractors, and agents), including court costs and legal
        fees, from any claim made by any third party caused by or arising out of (a) your use of our
        services, (b) your breach of these TOS, (c) your breach of any applicable laws or
        regulations, or (d) your content. We reserve the right, at your expense, to assume the
        exclusive defence and control of any such matter, and you agree to cooperate with our
        defence of these claims. You must not settle any claim without our prior written consent
        (not to be unreasonably withheld, conditioned or delayed). This indemnity is a continuing
        obligation, independent from the other obligations under these TOS, and continues after
        termination of these TOS.
      </Text>

      <Title variant="h2">20. General</Title>
      <Text>
        20.1 Severability: In the event that any provision of these TOS is determined to be void,
        invalid, unlawful or unenforceable then that provision or part will be deemed to be severed
        from these TOS and the remaining terms and provisions of these TOS will remain in force and
        constitute the agreement between you and Home.
      </Text>
      <Text>
        20.2 Assignment: If Home merges, sells or otherwise changes control of its business or this
        Site to a third-party, Home reserves the right, without giving notice or seeking consent, to
        transfer or assign the personal information, content and rights that Home has collected from
        you and any agreements it has made with you. You must not assign or transfer these TOS
        without the written consent of Home.
      </Text>
      <Text>
        20.3 Waiver: Any delay or failure by us to exercise or enforce any right or provision of
        these TOS will not constitute a waiver of such right or provision. No waiver by us will have
        effect unless such waiver is set forth in writing, signed by us, nor will any such waiver of
        any breach or default constitute a waiver of any subsequent or similar breach or default.
      </Text>
      <Text>
        20.4 Entire Agreement: Together with our Privacy Policy, these TOS constitute the entire
        agreement between you and Home with respect to its subject matter, and supersedes all prior
        oral or written understandings, communications or agreements.
      </Text>

      <Title variant="h2">21. Contact Information</Title>
      <Text>
        For any questions, concerns, or requests related to these TOS, please contact us at:
        help@home.cc or Level 1, 56 Clarence Street, Sydney NSW 2000 or (02) 6618 3388.
      </Text>
    </div>
  );
};

export default TermsAustralia;
